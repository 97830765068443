import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./pel.module.scss"
import Img from "gatsby-image"
import Cookies from 'universal-cookie'
import ReactWOW from "react-wow/"

export default ({data}) => {
    const cookies = new Cookies();
    var visited = cookies.get('visited');
    if (typeof visited === 'undefined') {
        cookies.set('visited', true, {path: '/'});
    }
    return (
        <Layout>
            <SEO title="Mercancías Peligrosas | Aerolineas Ejecutivas"/>

            <section className={style.pelHeader}>
                <Img className={style.img} fluid={data.portada.childImageSharp.fluid}/>
                {/*<PlayerHome/>*/}
            </section>


            <ReactWOW animation='fadeIn'>

                <section className={style.pelSubtitle}>
                    <h1>Mercancías Peligrosas</h1>
                </section>

            </ReactWOW>

            <ReactWOW animation='fadeIn'>

                <section className={`${style.listDark} ${style.list}`}>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img1?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Armas de fuego o armas blancas</p>
                        <p><small>Sin previo registro.</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img2?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Bebidas Alcohólicas</p>
                        <p><small>Que no excedan de 70% por volumen, envasadas en recipientes de menos de 5L.</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img3?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Estupefacientes o psicotrópicos</p>
                        <p><small>Sin autorización de la autoridad correspondiente.</small></p>
                    </div>
                </section>

                <section className={style.list}>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img4?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Hielo Seco</p>
                        <p><small>Cantidades que no sorepasen los 2kg. (4.4lb) por pasajero.</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img5?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Termómetro Médico</p>
                        <p><small>Que contenga mercurio, para uso personal, cuando vaya en su envase de protección.</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img6?.childImageSharp.fluid} />
                        <hr/>
                        <p>Silla de ruedas</p>
                        <p><small>U otra ayuda motriz con baterías derramables (consulte restricciones).</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img7?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Fósforos de seguridad o un encendedor</p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img8?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Dispositivos piroténicos</p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img9?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Objeto fuente de calor como linternas submarinas</p>
                        <p><small>Lámparas de buceo o soldadores.</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img10?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Rizadores para el cabello</p>
                        <p><small>que contenga hidrocarburo gaseoso. No más de uno por pasajero o tripulante, siempre que la tapa de seguridad esté firmemente encajada sobre el elemento calefactor.</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img11?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Aerosoles</p>
                        <p><small>Cuando la cantidad total neta de todos los artículos citados transportada por pasajero o tripulante no sobrepase los 2kg. (4.4lb) o 2L.</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img12?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Gas comprimido.</p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img13?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Cigarros electrónicos</p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img14?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Batería de Litio</p>
                        <p><small>Sin previo registro</small></p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img15?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Hoverboard o tabla voladora</p>
                    </div>
                    <div className={style.item}>
                        <Img className={style.img} fluid={data.img16?.childImageSharp.fluid}/>
                        <hr/>
                        <p>Pequeño generador de oxígeno</p>
                        <p><small>Para uso personal (consulte restricciones).</small></p>
                    </div>
                </section>

            </ReactWOW>


        </Layout>
    )
}



export const query = graphql`
    query ImagesMerpel {
    portada: file(relativePath: {eq: "pel/merpel.jpg"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 1920) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
    img1: file(relativePath: {eq: "pel/1.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img2: file(relativePath: {eq: "pel/2.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img3: file(relativePath: {eq: "pel/3.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img4: file(relativePath: {eq: "pel/4.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img5: file(relativePath: {eq: "pel/5.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img6: file(relativePath: {eq: "pel/6.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img7: file(relativePath: {eq: "pel/7.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
   
    img8: file(relativePath: {eq: "pel/8.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img9: file(relativePath: {eq: "pel/9.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img10: file(relativePath: {eq: "pel/10.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img11: file(relativePath: {eq: "pel/11.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img12: file(relativePath: {eq: "pel/12.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img13: file(relativePath: {eq: "pel/13.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img14: file(relativePath: {eq: "pel/14.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img15: file(relativePath: {eq: "pel/15.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img16: file(relativePath: {eq: "pel/16.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 90) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`

